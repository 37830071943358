<template>
  <div class="container-fluid h-auto">
    <div class="row mb-4">
      <div class="col-12">
        <BreadCrumb :links="[
          {
            name: 'Application Focus',
            params: {
              uuid: getApplicationUuid
            }
          },
          {
            name: getApplicationFullName + ' Traced Pension Details'
          }
        ]"/>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <div class="row">
              <div class="col-6">
                <CopyText :value="getPensionUuid" title="UUID: "/>
              </div>
              <div class="col-6 text-right">
                <div class="pointer d-inline" @click="finderLink">
                  <icon :data="finder" color="#69cab4" dir="up" height="26" role="button" width="26"/>
                  &nbsp;<span class=" ml-1 copy-title link-text">Open trace in finder</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-lg-6 col-12 mb-4">
        <div class="card h-100 shadow-sm">
          <div class="card-body">
            <h5 class="card-title d-flex justify-content-between align-items-center mb-4">
              Traced Pension Details
              <button v-if="canTranslateNav()" class="btn btn-primary btn-sm" @click.prevent="goToTranslateV2()">
                View Translate V2
              </button>
            </h5>
            <div class="row text-left">
              <div v-if="inDevelopment" class="col-12 mb-4">
                <span class="btn btn-secondary btn-sm" @click="handleSendFinderData">send to finder</span>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <p>
                      <strong>Id:</strong><br/>
                      <template v-if="(getUserPermissions)?.includes('can-view-logs')">
                        <PopOver content="view activity log">
                          <router-link 
                            :to="{ 
                              name: 'Activity Log', 
                              query: { subject_id: tracedPension?.id }
                            }"
                            class="text-primary"
                          >
                            {{ tracedPension?.id }}
                          </router-link>
                        </PopOver>
                      </template>
                      <template v-else>
                        {{ tracedPension?.id }}
                      </template>
                    </p>
                  </div>
                  <div class="col-6">
                    <p>
                      <strong>Policy number:</strong><br/>
                      {{
                        typeof tracedPension !== "undefined"
                            ? tracedPension.policy_number || missingText
                            : missingText
                      }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p>
                      <strong>Date of birth:</strong><br/>
                      {{ formatDate(getApplication?.dob) }}
                    </p>
                  </div>
                  <div v-if="tracedPension?.translate_id" class="col-6">
                    <p>
                      <strong>Translate ID:</strong><br/>
                      {{ tracedPension?.translate_id }}
                    </p>
                  </div>
                  <div v-if="tracedPension?.translate_status" class="col-6">
                    <p>
                      <strong>Translate Status:</strong><br/>
                      {{ tracedPension?.translate_status }}
                    </p>
                  </div>
                  <div v-if="tracedPension?.defined_benefits" class="col-6">
                    <p>
                      <strong>Defined benefits:</strong><br/>
                      {{ tracedPension?.defined_benefits }}
                    </p>
                  </div>

                  <div v-if="tracedPension?.note" class="col-12">
                    <p class="mb-0"><strong>Notes:</strong></p>
                    <p style="white-space: pre-line">
                      {{ formatNote(tracedPension.note) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <div class="font-weight-bold">Address:</div>
                    <div v-html="getAddress"></div>
                  </div>
                  <div class="col-12 mt-2">
                    <GenerateLOA :tasks="getTasks" :uuid="getPensionUuid" @handle-action="handleAction"/>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-6 mt-4">
                    <div class="card border-dark mb-3 shadow-sm">
                      <div class="card-header bg-light">
                        <h5 class="card-title mb-0">Status Information</h5>
                      </div>
                      <div class="card-body text-dark">
                        <StatusUpdate
                            ref="statusUpdateComponent"
                            :entity="tracedPension"
                            :with-title="false"
                            class="w-100" @status-changed="handleStatus"/>
                        <p v-if="
                      tracedPension?.status == 'complete' &&
                      tracedPension?.closed_reason" class="mt-3 p-2 bg-light rounded">
                          <strong>Completed reason:</strong><br/>
                          <span class="text-muted">{{
                            typeof tracedPension !== "undefined"
                                ? tracedPension.closed_reason
                                : missingText
                          }}</span>
                        </p>
                        <div v-if="tracedPension?.traced_pension_state?.length > 1">
                          <hr class="my-4">
                          <h6 class="mb-3">Status History</h6>
                          <StatusHistory
                              :state-current="tracedPension.current_traced_pension_state"
                              :state-previous="tracedPension.previous_traced_pension_state"
                              :state-list="tracedPension.traced_pension_state"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- <div class="card border-dark mb-3">
                      <div class="card-body text-dark">
                        <StatusUpdate
                            ref="statusUpdateComponent"
                            :entity="tracedPension"
                            class="w-100" @status-changed="handleStatus"/>
                        <p v-if="
                      tracedPension?.status == 'complete' &&
                      tracedPension?.closed_reason" class="mt-3">
                          <strong>Completed reason:</strong><br/>
                          {{
                            typeof tracedPension !== "undefined"
                                ? tracedPension.closed_reason
                                : missingText
                          }}
                        </p>
                        <div v-if="tracedPension?.traced_pension_state?.length > 1">
                          <hr>
                          <StatusHistory
                              :state-current="tracedPension.current_traced_pension_state"
                              :state-previous="tracedPension.previous_traced_pension_state"
                              :state-list="tracedPension.traced_pension_state"
                          />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <TracedPensionForm :aggregation-report="getStoredAggregationReport" :traced-Pension="tracedPension"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12 mb-4">
        <!-- Provider Details -->
        <div class="card h-100">
          <div :class="['card-body h-100 text-left', providerEditDisabled]"
               :title="titleAlertText">
            <div class="row text-left">
              <div class="col-6"><h5 class="card-title">Matched Provider Details</h5></div>
              <div class="col-6"><h5 class="card-title">Matched Employer Details</h5></div>
              <div class="col-6">
                <div v-if="typeof providerName !== 'undefined' && providerName !== null && getFinderStatus === true"
                     class="row">
                  <div class="col-12">
                    <p>
                      <strong>Pension provider:</strong><br/>
                      <span class="link-text" @click="navFinderProvider">{{ providerName }}</span>
                    </p>
                  </div>
                  <div v-if="providerScheme !== null" class="col-12">
                    <p>
                      <strong>Scheme name:</strong><br/>
                      {{ providerScheme }}
                    </p>
                  </div>
                  <div class="col-12">
                    <p>
                      <strong>Pension provider address:</strong><br/>
                      {{ providerAddress }}
                    </p>
                  </div>
                  <div class="col-12">
                    <p>
                      <strong>Pension provider email:</strong><br/>
                      {{ pensionProviderEmail }}
                    </p>
                  </div>
                </div>
                <div v-else> Please choose a provider</div>
              </div>
              <div class="col-6">
                <div v-if="employerName !== null && getFinderStatus === true" class="row">
                  <div class="col-12">
                    <p>
                      <strong>Name:</strong><br/>
                      <span class="link-text" @click="navFinderEmployer">{{ currentEmployer?.name }}</span>
                    </p>
                  </div>
                  <div class="col-12">
                    <p>
                      <strong>Inferred dates ( earliest - latest ):</strong><br/>
                      {{ formatReadDate(currentEmployer?.date_earliest) }} - {{
                        formatReadDate(currentEmployer?.date_latest)
                      }}
                    </p>
                  </div>
                  <div v-if="currentEmployer?.date_from" class="col-12">
                    <p>
                      <strong>Hard dates ( from - to ):</strong><br/>
                      {{ formatReadDate(currentEmployer?.date_from) }} - {{ formatReadDate(currentEmployer?.date_to) }}
                    </p>
                  </div>
                  <div v-if="currentEmployer?.industries.length > 0" class="col-12">
                    <p>
                      <strong>Industries:</strong><br/>
                      {{ getLast(currentEmployer?.industries)?.name }}
                    </p>
                  </div>
                  <div class="col-12">
                    <p>
                      <strong>Status:</strong><br/>
                      {{ parseStatus(currentEmployer?.status) }}
                    </p>
                  </div>
                  <div v-if="currentEmployer?.successor" class="col-12">
                    <p>
                      <strong>Successor:</strong><br/>
                      {{ currentEmployer?.successor?.name }}
                    </p>
                  </div>
                  <div v-if="currentEmployer?.parent" class="col-12">
                    <p>
                      <strong>Parent:</strong><br/>
                      {{ currentEmployer?.parent?.name }}
                    </p>
                  </div>
                </div>
                <div v-else> Please choose an employer</div>
              </div>
            </div>
            <FinderKnowledgeBase :data-object="finderOutcome" :inline="false" class="mt-4" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-12 mb-4">
        <!-- User Supplied Details -->
        <div class="card h-100">
          <div class="card-body h-100 text-left">
            <h5 class="card-title">User Supplied Details</h5>
            <div class="row text-left">
              <div class="col-12">
                <p>
                  <strong>Provider name:</strong><br/>
                  {{ userSuppliedProvider }}
                </p>
              </div>
              <div class="col-12">
                <EmploymentDetails :employment-data="tracedPensionEmployment"/>
              </div>
            </div>

            <EmploymentForm v-if="getTracedPensionEmployment" :employment-data="getTracedPensionEmployment"
                            route="traced-pension"/>
            <EmploymentFormAdd v-if="!getTracedPensionEmployment" route="traced-pension"/>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12 mb-4">
        <!-- Email -->
        <EmailCorrespondance/>
      </div>
      <div class="col-lg-3 col-12 mb-4">
        <!-- Documents -->
        <Documents :file="getFiles" @export-action="exportAction"/>
      </div>
      <div v-if="events?.length > 0" class="col-9">
        <EventList :events="events"/>
      </div>
    </div>
  </div>
</template>

<script>
import {provide, reactive} from 'vue';
import {mapActions, mapGetters, mapMutations, useStore} from "vuex";
import {useRoute} from "vue-router";
import formulateErrorString from "@/composables/useFormulateErrorString";
import GenerateLOA from "@/components/application-manager/GenerateLOA";
import Documents from "@/components/misc/Documents";
import CopyText from "@/components/misc/CopyText";
import {useDateFormat} from "@/logic/useDateFormat";
import TracedPensionForm from "@/components/form/TracedPensionForm";
// import ProviderForm from "@/components/form/ProviderForm";
import FinderKnowledgeBase from "@/components/finder/FinderKnowledgeBase.vue";
import EmailCorrespondance from "@/components/email-manager/EmailCorrespondance";
import EmploymentDetails from "@/components/lists/EmploymentDetails";
import EmploymentForm from "@/components/form/EmploymentForm";
import EmploymentFormAdd from "@/components/form/EmploymentFormAdd";
import StatusUpdate from "@/components/form/StatusUpdate";
import StatusHistory from "@/components/lists/StatusHistory";
import EventList from "@/components/events/EventList";
import finder from "@fa/solid/fingerprint.svg";


export default {
  name: "TracedPension",
  components: {
    GenerateLOA,
    CopyText,
    TracedPensionForm,
    // ProviderForm,
    FinderKnowledgeBase,
    EmailCorrespondance,
    EmploymentDetails,
    EmploymentForm,
    EmploymentFormAdd,
    StatusUpdate,
    StatusHistory,
    Documents,
    EventList
  },
  data: () => ({
    missingText: "Not available",
    translateBtnText: "View&nbsp;Translate&nbsp;V1",
    finder
  }),
  setup() {
    const store = useStore();
    const {formatDate} = useDateFormat();
    const route = useRoute();
    // const formulateErrorString = useFormulateErrorString();
    // let files = reactive({});

    const getFiles = async () => {
      let uuid = route.params.tpuuid;
      let type = "traced-pension";
      store.commit('setFiles', null);
      let response = await store.dispatch('getDocuments', {uuid, type});
      store.commit('setFiles', response.data.data);
    };

    const fetchPensionDetails = async (withDocs = true) => {
      store.commit('setLoading', {
        bool: true
      });
      store.commit('clearProviderUpdatedData');
      store.commit('clearSchemeUpdatedData');

      await store.dispatch('getTracedPensionFocus', route.params.tpuuid).catch(err => {
        store.commit('setLoading', {
          bool: false
        });
        store.commit('setNotification', {
          show: true,
          type: "error",
          message: "Traced pension error!",
          content: formulateErrorString(err)
        });
      });

      // await this.fetchProviderDetails();

      await store.dispatch('fetchFinderEmployerStatus').catch(err => console.log(err));

      store.commit('setLoading', {
        bool: false
      });

      if (withDocs) {
        await getFiles();
      }
    };

    provide('fetchPensionDetails', fetchPensionDetails);

    return {
      formatDate,
      fetchPensionDetails,
      getFiles,
      // files
    };
  },
  methods: {
    ...mapActions([
      "triggerAction",
      "getDocuments",
      "getProviderChildren",
      "sendAggregationReport",
      "getProviderData",
      "updateTracedPension",
      "getApplicationsFocus",
      "fetchProviderList",
      "fetchEmploymentList",
      "checkSchedule",
      "postFabricFinderV2Data",
      "fetchFinderEmployerStatus",
      "generateAggregationReport",
      "fetchFinderEmploymentList",
      "fetchFinderSourceList",
    ]),
    ...mapMutations([
      "setLoading",
      "setNotification",
      "setProviderUpdatedData",
      "setSchemeUpdatedData",
      "setFiles",
      "setPensionSchemes"
    ]),
    canTranslateNav() {
      if (this.tracedPension?.translate_status == null) {
        if (this.tracedPension?.translate_id !== null) {
          return true;
        }
        return false;
      }
      switch (this.tracedPension?.translate_status) {
        case 'draft':
        case 'pre-processing':
        case 'pending-delete':
          return false;
        default:
          return true;
      }
    },
    navFinderProvider() {
      window.open(process.env.VUE_APP_FINDER_URL + '/PensionProviderScheme/' + this.providerId, '_blank');
    },
    navFinderEmployer() {
      window.open(process.env.VUE_APP_FINDER_URL + '/Employer/' + this.employerId, '_blank');
    },
    finderLink() {
      window.open(process.env.VUE_APP_FINDER_URL + "/Trace/" + this.$route.params.tpuuid, '_blank');
    },
    parseStatus(int) {
      switch (int) {
        case 0:
          return 'Active';
        case 1:
          return 'No pension';
      }
    },
    getTranslateBtnText() {
      switch (this.tracedPension?.status) {
        case 'LOR Translate Complete':
          return 'Generate&nbsp;Pension&nbsp;Report';
        case 'LOR Review':
          return 'View&nbsp;Translate&nbsp;V1';
        default:
          return '';
      }
    },
    async handleSendFinderData() {
      await this.postFabricFinderV2Data({
        'uuid': this.$route.params.tpuuid,
        'employer_name': this.getTracedPensionEmployment?.name_of_employer,
      }).catch(err =>
          console.log(err)
      );
    },
    async handleStatus(param) {
      let tempParams = {};
      let provider = {
        id: this.tracedPension.id,
        status: param.status,
        closed_reason: param.closed_reason,
        pushFinderMatch: param.pushFinderMatch
      };
      tempParams.pensions = [];
      tempParams.pensions.push({
        provider
      });

      if (param.status === 'closed') {
        tempParams.dispatchSubmitFinderMatch = true;
      }

      await this.updateTracedPension({tempParams}).catch(err =>
          console.log(err)
      );

      this.$refs.statusUpdateComponent.edit = false;
      this.$refs.statusUpdateComponent.updating = false;

      let schedule = {
        uuid: this.tracedPension.uuid,
        event: "status-change"
      };
      let response = await this.checkSchedule(schedule).catch(err =>
          console.log(err)
      );

      if (response.data.status === "need more info") {
        if (
            !confirm(
                "This email requires extra information. \r\nWould you like to send an email now?"
            )
        )
          return;
        this.$router.push({
          name: "Email Send Interface",
          params: {
            tpuuid: this.tracedPension.uuid
          },
          query: {
            template: response.data.message,
            parent: 'tp',
          },
        });
      }
    },
    goToTranslateV2() {
      if (this.tracedPension?.translate_id === null) {
        this.setNotification({
          show: true,
          type: "error",
          message: "No translate_id detected!"
        });
        return;
      }
      window.open(`${process.env.VUE_APP_TRANSLATE_V2_URL}/#/TranslatePage/${this.tracedPension?.translate_id}`, '_blank');
    },
    handleTranslateNav(version) {
      switch (version) {
        case 'v1':
          this.$router.push({
            name: 'Fabric Translate Extracted Pages',
            params: {
              auuid: this.tracedPension.application.uuid,
              tpuuid: this.tracedPension.uuid,
            }
          });
          break;
        case 'v2-complete':
          this.handleGenerate();
          break;
      }
    },
    async exportAction() {
      if (!confirm("Email the applicant their Pension Report?")) return;
      await this.sendAggregationReport(this.tracedPension?.uuid);
    },
    async handleAction({tp_uuid, type}) {
      // if (!confirm("Action this task?")) return;
      this.setLoading({
        bool: true,
        message: "Acting..."
      });
      let response = await this.triggerAction({
        traced_pension_uuid: tp_uuid,
        // task_id: 0,
        type,
        model: "pension"
      });

      if (response) {
        this.tracedPension.task = response.data.data.task;
        this.getFiles();
        this.setNotification({
          show: true,
          type: "success",
          message: "Task is processing..."
        });
      }
      this.setLoading({bool: false});
    },
    async fetchProviderDetails() {

      if (this.provider) {
        let response = await this.getProviderChildren(this.provider.id).catch(
            err => {
              this.setLoading({
                bool: false
              });
              this.setNotification({
                show: true,
                type: "error",
                message: "Provider error!",
                content: this.formulateErrorString(err)
              });
            }
        );

        this.setPensionSchemes(response.data);

        let miscResponse = await this.getProviderData({
          id: this.provider.id
        }).catch(err => {
          this.setLoading({
            bool: false
          });
          this.setNotification({
            show: true,
            type: "error",
            message: "Provider error!",
            content: this.formulateErrorString(err)
          });
        });

        if (miscResponse.data.parent_id) {
          let providerResponse = await this.getProviderData({
            id: miscResponse.data.parent_id?.id
          }).catch(err => {
            this.setLoading({
              bool: false
            });
            this.setNotification({
              show: true,
              type: "error",
              message: "Provider error!",
              content: this.formulateErrorString(err)
            });
          });

          this.setSchemeUpdatedData(
              this.setDataObject({
                params: {id: miscResponse.id},
                response: miscResponse
              })
          );
          this.setProviderUpdatedData(
              this.setDataObject({
                params: {id: this.provider.id},
                response: providerResponse
              })
          );
        } else {
          this.setProviderUpdatedData(
              this.setDataObject({
                params: {id: this.provider.id},
                response: miscResponse
              })
          );
        }
      }
    },
    async handleGenerate() {
      this.setLoading({
        bool: true
      });
      let response = await this.generateAggregationReport({
        auuid: this.getStoredAggregationReport.uuid
      }).catch(err => console.log(err => {
        this.setLoading({
          bool: false
        });
        this.setNotification({
          show: true,
          type: "error",
          message: "Pension report error!",
          content: this.formulateErrorString(err)
        });
      }));
      this.setLoading({
        bool: false
      });
      if (response.status === 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Report is being generated..."
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "getApplication",
      "getApplicationUuid",
      "getApplicationFullName",
      "getTracedPension",
      "getPusherNotification",
      "getPensionSchemes",
      "getProviderUpdatedData",
      "getSchemeUpdatedData",
      "getStatusTPValues",
      "getStoredAggregationReport",
      "getCurrentAddress",
      "getStoredAggregationReport",
      "getFinderEmployerStatus",
      "getFinderStatus",
      "getStatusClosedReason",
      "getFiles",
      "getUserPermissions",
    ]),
    titleAlertText() {
      return this.getFinderStatus === false ? 'Please click the red fingerprint in the User Supplied Details section' : '';
    },
    providerEditDisabled() {
      return {
        '-disable-provider-edit': this.getFinderStatus === false,
      }
    },
    translateRoute() {
      switch (this.tracedPension?.status) {
        case 'LOR - Missing Info':
        case 'LOR Translate In Progress':
          return 'v2';
        case 'LOR Translate Complete':
          return 'v2-complete';
        case 'LOR Review':
          return 'v1';
        default:
          return '';
      }
    },
    inDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
    getOwner() {
      if (
          typeof this.getApplication?.assigned_user == "undefined" ||
          this.getApplication?.assigned_user?.length < 1
      ) {
        return "Unassigned";
      } else {
        let owner = this.getApplication?.assigned_user[
        this.getApplication?.assigned_user?.length - 1
            ];
        return owner.first_name + " " + owner.last_name;
      }
    },
    tracedPensionEmployment() {
      return typeof this.tracedPension !== "undefined"
          ? this.tracedPension?.employment !== null
              ? this.tracedPension.employment
              : this.missingText
          : this.missingText;
    },
    userSuppliedProvider() {
      return typeof this.tracedPension !== "undefined"
          ? this.tracedPension?.user_supplied_provider_name !== null
              ? this.tracedPension.user_supplied_provider_name
              : this.missingText
          : this.missingText;
    },
    finderOutcome() {
      return {
        source: {
          id: this.providerSchemeId || this.providerId,
          name: this.providerScheme || this.providerName
        },
        employer: {
          id: this.employerId,
          name: this.employerName
        },
      }
    },
    providerName() {
      return this.currentProvider?.current_parent !== null ? this.currentProvider?.current_parent?.name : this.currentProvider?.name;
    },
    providerId() {
      return this.currentProvider?.current_parent !== null ? this.currentProvider?.current_parent?.id : this.currentProvider?.id;
    },
    providerScheme() {
      return this.currentProvider?.current_parent !== null ? this.currentProvider?.name : null
    },
    providerSchemeId() {
      return this.currentProvider?.current_parent !== null ? this.currentProvider?.id : null
    },
    employerName() {
      return this.currentProvider?.employer?.name || null;
    },
    employerId() {
      return this.currentProvider?.employer?.id || null;
    },

    pensionProviderEmail() {
      return this.currentProvider?.email !== null
          ? this.currentProvider?.email
          : this.missingText;
    },
    pensionProviderContact() {
      return this.currentProvider?.current_phone_number_formatted !== null &&
      typeof this.provider?.current_phone_number_formatted !== "undefined"
          ? this.currentProvider?.current_phone_number_formatted
          : this.missingText;
    },
    communicationByPost() {
      return this.currentProvider?.requires_communication_by_post !== null
          ? this.affirm(this.currentProvider?.requires_communication_by_post)
          : this.missingText;
    },
    requiresWetSignature() {
      return this.currentProvider?.requires_wet_signature !== null
          ? this.affirm(this.currentProvider?.requires_wet_signature)
          : this.missingText;
    },
    requiresEncryption() {
      return this.currentProvider?.requires_encryption !== null
          ? this.affirm(this.currentProvider?.requires_encryption)
          : this.missingText;
    },
    responseGoesToCustomer() {
      return this.currentProvider?.response_goes_to_customer !== null
          ? this.affirm(this.currentProvider?.response_goes_to_customer)
          : this.missingText;
    },
    providerAddress() {
      if (typeof this.currentProvider == "undefined") {
        return "No address available";
      }
      if (
          typeof this.currentProvider?.address == "undefined" ||
          this.currentProvider?.address?.length == 0 ||
          this.currentProvider?.address[0] === null ||
          this.currentProvider?.address[0].address_1 === null
      ) {
        return "No address available";
      }

      return this.formatAddress(
          this.currentProvider?.address[this.currentProvider?.address?.length - 1]
      );
    },
    currentProvider() {
      return this.tracedPension?.current_provider;
    },
    currentEmployer() {
      return this.tracedPension?.current_provider?.employer;
    },
    provider() {
      let providerArr = this.tracedPension?.pension_provider;
      if (typeof providerArr == "undefined") return null;
      return providerArr[providerArr?.length - 1];
    },
    schemes() {
      return this.getPensionSchemes;
    },
    tracedPensionStatus() {
      return this.tracedPension.status;
    },
    tracedPension() {
      return this.getTracedPension;
    },
    getAddress() {
      if (!this.getApplication?.address?.[0]?.address_1) {
        return "No address has been set";
      }

      return this.formatAddressHTML(this.getCurrentAddress);
    },
    getStatus() {
      let status = null;
      switch (this.tracedPension?.status) {
        case "in-progress":
          status = "in progress";
          break;
      }
      return status;
    },
    getTasks() {
      const task = this.tracedPension?.task;
      return task;
    },
    getPensionUuid() {
      return this.tracedPension?.uuid;
    },
    getTracedPensionEmployment() {
      return typeof this.tracedPension !== "undefined"
          ? this.tracedPension?.employment !== null &&
          typeof this.tracedPension.employment !== "undefined"
              ? this.tracedPension.employment[0]
              : null
          : null;
    },
    events() {
      return this.tracedPension?.event_caching;
    }
  },
  watch: {
    getPusherNotification(param) {
      switch (param.type) {
        case 'create-loa':
        case 'create-report':
          // this.getFiles();
          break;
      }
    }
  },
  async beforeMount() {
    let pensionDetails = await this.fetchPensionDetails();
    let finderEmployers = await this.fetchFinderEmploymentList().catch(err => console.log);
    let finderSource = await this.fetchFinderSourceList().catch(err => console.log);
    await Promise.all([pensionDetails, finderEmployers, finderSource]);
  },
  mounted() {
    // this.fetchProviderList().catch(err => console.log(err));
    // this.fetchEmploymentList().catch(err => console.log(err));
  }
}
</script>

<style lang="scss" scoped>
.card {
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

.btn-primary {
  background-color: $brandBlue;
  border-color: $brandBlue;

  &:hover {
    background-color: darken($brandBlue, 10%);
    border-color: darken($brandBlue, 10%);
  }
}

.link-text {
  color: $brandBlue;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: darken($brandBlue, 15%);
    text-decoration: underline;
  }
}

.-disable-provider-edit {
  opacity: 0.7;
  cursor: not-allowed;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
}

// Add more custom styles as needed
</style>
